export const SHARED_FIELDS = `
  entityLabel
  defaultLangcode
  fieldFile {
    targetId
    display
    description
    entity {
      url
    }
  }
  fieldLatitude
  fieldLongitude
  fieldMediaType {
    entity {
      ... on TaxonomyTermMediaType {
        name
        tid
      }
    }
  }
  fieldDescription {
    processed
    value
    summaryProcessed
  }
  fieldNewThumbnailUrl
  fieldMediaDateCreate {
    value
    date
  }
  entityOwner {
    name
    fieldDisplayName
    fieldPartnerDropdownName
    entityId
  }
  fieldEnglishTitle
  fieldEnglishDescription {
    processed
    value
  }
  fieldJapaneseTitle
  fieldJapaneseDescription {
    processed
    value
  }
`

const BASIC_ITEM_FIELDS = `
  items:entities @include(if: $withItems) {
    ... on GroupContentCollectionGroupNodeItem {
      id
      nodeId:entityIdOfGroupContent {
        id:targetId
      }
      entityIdOfGroupContent {
        entity {
          ... on NodeItem {
            nid
            fieldJapaneseTitle
            fieldEnglishTitle
            uid {
              targetId
            }
            status
          }
        }
      }
    }
  }
`

const NESTED_COLLECTION_ITEM_DATA = `
  entityIdOfGroupContent {
    entity {
      ... on NodeItem {
        nid
        fieldNewAttributionUri
        fieldNewUri
        uid {
          targetId
        }
        status
        ${SHARED_FIELDS}
      }
    }
  }
`

const COLLECTION_FIELDS = `
  id
  uuid
  changed
  langcode {
    value
  }
  fieldFeaturedCollection
  fieldCollectionTopic {
    entity {
      ... on CollectionTopic {
        id
        entityOwner {
          name
          fieldDisplayName
          fieldPartnerDropdownName
          entityId
        }
        englishTitle
        japaneseTitle
        englishDescription
        japaneseDescription
      }
    }
  }
  status
  ${SHARED_FIELDS}
`

const TEXT_SLIDES_SUBQUERY = `
  ... on GroupContentCollectionGroupNodeTextSlide {
    id
    label
    fieldCollectionTopic {
      entity {
        ... on CollectionTopic {
          id
        }
      }
    }
    fieldNotes
    fieldTopicWeight
    text_slides: entityIdOfGroupContent {
      entity {
        ... on NodeTextSlide {
          nid
          fieldEnglishTitle
          fieldJapaneseTitle
          fieldEnglishDescription {
            processed
          }
          fieldJapaneseDescription {
            processed
          }
        }
      }
    }
  }
`

const MEMBERS_SUBQUERY = `
  ... on GroupContentCollectionGroupMembership {
    id
    label
    path {
      alias
      pid
    }
    entityIdOfGroupContentCollectionGroupMembership {
      entity {
        uid
        name
        mail
      }
    }
  }
`

export const COLLECTION_BYID_QUERY = `
  query CollectionById($id: String!) {
    groupById(id: $id) {
      ... on GroupCollection {
        ${COLLECTION_FIELDS}
        metatag: entityMetatags {
          type: __typename
          property: key
          content: value
        }
        reverseGidGroupContent(limit:500) {
          entities {
            ... on GroupContentCollectionGroupNodeItem {
              ${NESTED_COLLECTION_ITEM_DATA}
              id
              label
              fieldNotes
              fieldSortOrder
              fieldTopicWeight
              fieldIncludeWakuPresentation
              fieldCollectionTopic {
                entity {
                  ... on CollectionTopic {
                    id
                  }
                }
              }
            }
            ${TEXT_SLIDES_SUBQUERY}
          }
        }
      }
    }
  }
`

export const USER_COLLECTIONS_QUERY = `
  query UserCollections(
    $userId: [String], 
    $withItems: Boolean!, 
    $offset: Int!, 
    $limit: Int!, 
    $sortField: String!,
    $sortOrder: SortOrder
  ) {
    groupQuery(
      offset: $offset, 
      limit: $limit,
      sort: {
        field: $sortField,
        direction: $sortOrder
      },
      filter: {conditions: {field: "uid", value: $userId}}
    ) {
      totalCollections:count
      entities {
        ... on GroupCollection {
          ${COLLECTION_FIELDS}
          items:reverseGidGroupContent(limit: 999, filter: {conditions: {field: "type", value: "collection-group_node-item"}}) {
            collectionItemCount:count
            ${BASIC_ITEM_FIELDS}
          }
        }
      }
    }
  }
`

export const COLLECTION_BYID_GROUP_QUERY = `
  query CollectionById($id: String!, $withItems: Boolean!) {
    groupById(id: $id) {
      ... on GroupCollection {
        ${COLLECTION_FIELDS}
        items:reverseGidGroupContent(limit: 999, filter: {conditions: {field: "type", value: "collection-group_node-item"}}) {
          collectionItemCount:count
          ${BASIC_ITEM_FIELDS}
        }
      }
    }
  }
`

export const ALL_MEMBERS_OF_COLLECTIONS_QUERY = `
  query CollectionsAllMembers($groupId: String!){
    groupById(id: $groupId) {
      ... on GroupCollection {
        id
        uuid
        changed
        langcode {
          value
        }
        status
        entityOwner {
          name
          fieldDisplayName
          fieldPartnerDropdownName
          entityId
        }
        reverseGidGroupContent(limit: 500) {
          entities {
            ... on GroupContentCollectionGroupMembership {
              id
              label
              path {
                alias
                pid
              }
              entityIdOfGroupContentCollectionGroupMembership {
                entity {
                  uid
                  name
                  mail
                }
              }
            }
          }
        }
      }
    }
  }
`

export const COLLECTION_BY_IDS_QUERY = `
  query CollectionByIdsQuery(
    $conditions: [ConditionsInput],
    $withItems: Boolean!
  ) {
    groupQuery(
      limit: 999, 
      filter: {
        conjunction: OR, 
        conditions: $conditions
      }
    ) {
    entities {
      ... on GroupCollection {
        ${COLLECTION_FIELDS}
        items:reverseGidGroupContent() {
          collectionItemCount:count
          ${BASIC_ITEM_FIELDS}
        }
      }
    }
  }
`