import { SEARCH_PAGE_SIZE } from 'shared/constants'
import { getTagsByNodeId } from 'shared/utils/api/item'
import { get } from 'utils/axios'
import { processGroupByIdItem } from '../collections/model'
import { SHARED_FIELDS } from '../collections/query'
import { execute, buildConditions, parseSearchStringIntoArray } from '../utils'
import { processItems, processRelatedCollections } from './model'

export const getItemById = async (id) => {
  const url = `/jsonapi/user/user?filter[uid][value]=${id}`
  const res = await get(url)
  return res.data
}

const searchItems = async (filters, sorting = null, page, conditions) => {
  const sortMapping = {
    archived: 'created',
    published: 'field_media_date_create'
  }

  const withSort = sorting && sortMapping[sorting.sort] && sorting.sort !== 'relevance'
  const sortInput = withSort ? [{ field: sortMapping[sorting.sort], value: sorting.order || 'asc' }] : null

  const conditionGroup = filters && buildConditions(filters)

  const keywordArray = filters && filters.keyword && parseSearchStringIntoArray(filters.keyword)

  // field_media_date_create
  const query = `query SearchItems(
    $keywords: [String]!, 
    $offset: Int!, 
    $limit: Int!, 
    $sort: [SortInput],
    $conditions: [ConditionInput],
    $conditionGroup: ConditionGroupInput
  ) {
    searchAPISearch(
      index_id: "searchstax_index_items",
      fulltext: {
        keys: $keywords,
        conjunction: "AND"
        fields: [
          "field_english_title",
          "field_japanese_title",
          "field_english_description",
          "field_japanese_description",
          "title",
          "description",
          "summary",
          "field_latitude",
          "field_longitude",
          "field_text_location",
          "field_media_creator_realname",
          "field_media_creator_username",
          "layer_type_name",
          "media_type_name",
          "tag_names"
        ]
      },
      range: { offset: $offset, limit: $limit },
      sort: $sort,
      conditions: $conditions,
      condition_group: $conditionGroup
    ) {
      result_count
      documents {
        ... on SearchstaxIndexItemsDoc {
          nid
          title
          url
          description
          field_english_title
          field_english_description
          field_japanese_title
          field_japanese_description
          summary
          field_partner_dropdown_name
          field_add_to_partners_filter
          field_attribution_uri
          field_thumbnail_url
          created
          status
          uid
          author
          changed
          field_language
          langcode
          field_geolocation_lat
          field_geolocation_lng
          field_latitude
          field_longitude
          field_location
          field_text_location
          media_type_name
          field_retweet
          tag_names
          media_type_tid
          field_media_date_create
          field_media_creator_username
          field_media_creator_realname
          field_english_title
          field_english_description
          field_japanese_title
          field_japanese_description
        }
      }
    }
  }`

  const res = await execute(query, { 
    keywords: keywordArray || [], 
    limit: SEARCH_PAGE_SIZE,
    offset: (page - 1) * SEARCH_PAGE_SIZE,
    sort: sortInput,
    conditions: conditions || null,
    conditionGroup: !conditions && Boolean(conditionGroup) ? conditionGroup : null
  })
  return processItems(res)
}

const getPartners = () => {
  const query = `query {
    userQuery(limit: 200, filter:
      {conditions: [{field: "field_add_to_partners_filter", value: "1", operator: EQUAL}]}) {
      count
      entities {
        ... on User {
          uid
          created
          fieldPartnerDropdownName
          fieldDisplayName
          fieldJapaneseDisplayName
          fieldDescription
          fieldJapaneseDescription
          userPicture {
            url
          }
        }
      }
    }
  }`

  return execute(query)
}

const getUserItems = async uid => {
  const conditions = [{
    operator: "=",
    name: "uid",
    value: uid
  }]

  return await searchItems(null, null, 1, conditions)
}

const getNodeItemById = async id => {
  const query = `
    query NodeItem($id: String!) {
      nodeById(id: $id) {
        ... on NodeItem {
          nid
          uuid
          title
          metatag: entityMetatags {
            type: __typename
            property: key
            content: value
          }
          type {
            targetId
          }
          created
          langcode {
            value
          }
          fieldLanguage
          fieldTextLocation
          fieldMediaCreatorRealname
          fieldMediaCreatorUsername
          fieldTags {
            targetId
            entity {
              entityLabel
            }
          }
          fieldNewAttributionUri
          fieldNewUri
          showViewSourceButton
          forceDisplayAsTestimonial
          avoidUsingImage
          allowThumbnailsAllMedia
          removeViewSourceWebsiteArticles
          uid {
            targetId
          }
          status
          ${SHARED_FIELDS}
        }
      }
    }
  `
  const res = await execute(query, { id: typeof id === 'number' ? id.toString() : id })
  const tagsRes = await getTagsByNodeId(id)
  const relatedCollections = await getRelatedCollections(id)
  const processed = res && processGroupByIdItem(res)
  if (processed) {
    processed.tags = tagsRes || []
    processed.relatedCollections = relatedCollections && relatedCollections.slice(0,2)
  }
  return processed
}

export const searchTags = async value => {
  const query = `
    query SearchTags($name: [String]) {
      tags: taxonomyTermQuery(
        limit: 10,
        filter: {
          conditions: [
            {
              operator: LIKE,
              field: "name",
              value: $name
            },
            {
              operator: EQUAL,
              field: "vid",
              value: "tags"
            }
          ]
        }
      ) {
        count
        entities {
          ... on TaxonomyTermTags {
            tid
            name
          }
        }
      }
    }
  `
  const res = await execute(query, { name: `%${value}%` })
  return res && res.tags && [res.tags.entities, res.tags.count]
}

const getRelatedCollections = async id => {
  const query = `
    query RelatedCollections($id: String!){
      nodeById(id: $id) {
        ... on NodeItem {
          title
          uid {
            targetId
          }
          status
          nid
          reverseEntityIdGroupContent(sort: {field: "created", direction: DESC}) {
            entities {
              ... on GroupContentCollectionGroupNodeItem {
              queryGid(limit: 500, filter: {conditions: {field: "status", value: "1"}}){
                entities {
                  ... on GroupCollection {
                    fieldEnglishTitle
                    fieldJapaneseTitle
                    fieldNewThumbnailUrl
                    id
                    entityUrl {
                      path
                    }
                  }
                }
              }
              }
            }
          }
        }
      }
    }
  `
  const res = await execute(query, { id: typeof id === 'number' ? id.toString() : id })
  return processRelatedCollections(res)
}

export {
  searchItems,
  getPartners,
  getUserItems,
  getNodeItemById
}
