import { createSelector } from 'reselect'
import { sortItemByNewest, sortItemByOldest } from 'shared/utils/tools/datetime'
import { selectUser } from 'features/authentication/redux/authenticationSelectors'
import { selectLangcodeFlag } from 'shared/redux/sharedSelectors'
import { getLangValue } from 'utils/functions'
import { ADMINISTRATOR } from 'shared/constants'

// meta
export const selectItemModalOpen = state => state.collections.meta.itemModalOpen
export const selectItemModalType = state => state.collections.meta.itemModalType
export const selectCreateCollectionSubmitting = state => state.collections.meta.submitting
export const selectMyCollectionsSort = state => state.collections.meta.myCollectionsSort
export const selectCollectionDataNotFound = state => state.collections.meta.collectionDataNotFound
export const selectItemModalDataNotFound = state => state.collections.meta.itemModalDataNotFound
export const selectItemModalActiveTab = state => state.collections.meta.itemModalActiveTab

export const selectCollectionFormData = state => state.collections.collectionForm
export const selectUserItems = state => state.collections.userItems
export const selectUserCollectionCount = state => state.collections.userCollectionCount
export const selectUserCollections = state => state.collections.userCollections
export const selectCollectionData = state => state.collections.selectedCollectionData
export const selectCollectionMembers = state => state.collections.selectedCollectionData?.members

export const rawSelectItemData = state => state.collections.selectedItemData

// items inside of collections should only show up if they are 
export const selectItemData = createSelector(
  [rawSelectItemData, selectUser],
  (data, user) => {
    const userId = user.id
    let newData = data
    if (data.isCollection
      && Array.isArray(data?.reverseGidGroupContent?.entities)
      && data.reverseGidGroupContent.entities.length > 0
    ) {
      const newReverseGidGroupContent = data.reverseGidGroupContent.entities.filter(item => item?.entityIdOfGroupContent?.entity?.status || item?.entityIdOfGroupContent?.entity?.uid?.targetId === userId)
      newData = {
        ...data,
        reverseGidGroupContent: {
          ...data.reverseGidGroupContent,
          entities: newReverseGidGroupContent,
        }
      }
    }

    if (data.isCollection
      && Array.isArray(data?.items)
      && data.items.length > 0
    ) {
      const newItems = data.items.filter(item => item?.itemData?.uid?.targetId === userId)
      newData = {
        ...data,
        items: newItems
      }
    }

    return newData
  }
)

export const selectItemDataItems = createSelector(
  [selectItemData],
  (data) => {
    return data ? data.items : []
  }
)

// includes textSlides
export const selectCollectionDataItems = createSelector(
  [selectCollectionData],
  (data) => {
    return data ? data.items : []
  }
)

export const selectCollectionDataCollectionItems = createSelector(
  [selectCollectionDataItems],
  (data) => {
    return data.filter(item => item && item.type === 'collectionItem')
  }
)

export const selectCollectionDataPhotoItems = createSelector(
  [selectCollectionDataCollectionItems],
  (data) => {
    return data.filter(colItem => colItem.itemData && colItem.itemData.img).map(item => item.itemData)
  }
)

export const selectCollectionDataTopics = createSelector(
  [selectCollectionData],
  (data) => {
    return data ? data.fieldCollectionTopic : []
  }
)

export const selectCollectionDataTopicListConfg = createSelector(
  [selectCollectionDataTopics, selectLangcodeFlag],
  (data, lang) => {
    return data.map(topic => ({
      label: getLangValue(lang, { en: topic.entity.englishTitle, ja: topic.entity.japaneseTitle }),
      value: topic.entity.id
    }))
  }
)

export const selectSortedUserCollections = createSelector(
  [selectUserCollections, selectMyCollectionsSort, selectLangcodeFlag],
  (data, sortValue, lang) => {
    const sortFn = sortValue === 'newest' ? sortItemByNewest
      : sortValue === 'oldest' ? sortItemByOldest 
        : (a, b) => (a.title[lang] || '').localeCompare(b.title[lang] || '')
    return data.slice().sort(sortFn)
  }
)

// check if user is the owner of the item or if the user is an admin and the item is editable
export const isItemEditable = createSelector(
  [selectUser, selectItemData],
  (activeUser, data) => {
    if (activeUser && data) {
      const admin = activeUser?.roles?.length > 0 && Array.isArray(activeUser?.roles) && activeUser?.roles.includes(ADMINISTRATOR)
      //TODO add logic to check if item is editable
      return admin || `${data.ownerId}` === `${activeUser.id}`
    }
    return false
  }
)

export const selectIsUserCollectionDataOwner = createSelector(
  [selectUser, selectCollectionData],
  (activeUser, data) => {
    if (activeUser && data) {
      return `${data.ownerId}` === `${activeUser.id}`
    }
    return false
  }
)

/**
 * @returns member or undefined, member = { uid, gid, email, isOwner? } 
 */
export const selectUserCollectionMember = createSelector(
  [selectUser, selectCollectionMembers],
  (activeUser, members) => {
    if (activeUser && members?.length > 0) {
      return members.find(member => parseInt(member.uid) === parseInt(activeUser.id))
    }
  }
)